import {Service1,RehabServices} from "../Components/Rehabservices";
import Timeline from "../Components/TimelineServices";
const Services = () => {
  return (
    <div>
      <Service1/>
      <RehabServices/>
      <Timeline/>      
    </div>
  );
};

export default Services;

import React from 'react';

const HighlightedFeatures = () => {
  return (
    <div className="max-w-screen-xl bg-[#F3FAF7] mx-auto py-8 px-4 lg:py-16 lg:px-6 animate-fade-in">
      {/* Main Heading */}
      <div className="text-center mb-10 animate-slide-up">
        <h2 className="text-4xl tracking-tight font-bold text-primary-800">About Us</h2>
      </div>

      <div className="flex flex-col md:flex-row animate-slide-up">
        {/* Image Section */}
        <div className="mr-0 md:mr-8 mb-6 md:mb-0">
          <img
            className="w-1/2 md:w-full mx-auto "
            src="logo1.png"
            alt="can_help_banner"
          />
        </div>

        {/* Features Section */}
        <div className="flex-1 flex flex-col sm:flex-row flex-wrap -mb-4 -mx-2">
          {features.map((feature, index) => (
            <div
              key={index}
              className="w-full sm:w-1/2 mb-4 px-2 transition-transform duration-500 ease-out transform hover:scale-105"
            >
              <div className="h-full py-4 px-6 border border-green-500 border-t-0 border-l-0 rounded-br-xl animate-fade-in">
                <h3 className="text-2xl font-bold text-md mb-6">{feature.title}</h3>
                <p className="text-sm">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Inline Styles */}
      <style jsx>{`
        @keyframes fade-in {
          0% { opacity: 0; transform: translateY(20px); }
          100% { opacity: 1; transform: translateY(0); }
        }

        @keyframes slide-up {
          0% { transform: translateY(30px); opacity: 0; }
          100% { transform: translateY(0); opacity: 1; }
        }

        .animate-fade-in {
          animation: fade-in 1s ease-out forwards;
        }

        .animate-slide-up {
          animation: slide-up 1s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

// Feature data
const features = [
  {
    title: "Alcohol De Addiction",
    description: "Comprehensive care includes medically supervised detox, personalized therapy, and support groups like AA. Emphasis on holistic recovery with educational programs and family involvement to support long-term sobriety."
  },
  {
    title: "Drug De addiction",
    description: "Tailored programs include safe detox, behavioral therapies like CBT and DBT, and support groups such as NA. Life skills training and contingency management help build a foundation for a drug-free life."
  },
  {
    title: "24/7 Customer Support",
    description: "A dedicated helpline offers round-the-clock assistance for inquiries, emergencies, and admissions. Trained staff ensure immediate, compassionate support, guiding patients and families through the recovery process."
  },
  {
    title: "Privacy",
    description: "Strict confidentiality policies protect patient information, with secure record management and private therapy sessions. Staff receive regular training to uphold privacy standards, ensuring a safe, confidential environment."
  }
];

export default HighlightedFeatures;
